// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chat-group-master-404-js": () => import("./../../../src/pages/chat-group-master/404.js" /* webpackChunkName: "component---src-pages-chat-group-master-404-js" */),
  "component---src-pages-chat-group-master-index-js": () => import("./../../../src/pages/chat-group-master/index.js" /* webpackChunkName: "component---src-pages-chat-group-master-index-js" */),
  "component---src-pages-chat-group-master-multiple-js": () => import("./../../../src/pages/chat-group-master/multiple.js" /* webpackChunkName: "component---src-pages-chat-group-master-multiple-js" */),
  "component---src-pages-chat-group-master-server-js": () => import("./../../../src/pages/chat-group-master/server.js" /* webpackChunkName: "component---src-pages-chat-group-master-server-js" */),
  "component---src-pages-chat-group-master-single-js": () => import("./../../../src/pages/chat-group-master/single.js" /* webpackChunkName: "component---src-pages-chat-group-master-single-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipe-page-master-index-js": () => import("./../../../src/pages/recipe-page-master/index.js" /* webpackChunkName: "component---src-pages-recipe-page-master-index-js" */),
  "component---src-pages-recipe-page-master-recipe-page-master-js": () => import("./../../../src/pages/recipe-page-master/recipe-page-master.js" /* webpackChunkName: "component---src-pages-recipe-page-master-recipe-page-master-js" */)
}

